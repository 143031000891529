import "fancybox/dist/js/jquery.fancybox.js";
import "jquery-sticky";
import _throttle from "lodash/throttle";
import "bootstrap/js/src/tab";

$(function() {
	$(".fancybox").fancybox({
		helpers: {
			overlay: {
				locked: false
			}
		}
	});

	var serviceTimer;

	function repositionServiceCarousel() {
		$(".service-carousel-container").each(function() {
			var $this = $(this),
				$main = $this.closest(".service-main"),
				thisOffY =
					$this.offset().top - parseInt($this.css("margin-top"), 10),
				mainOffY = $main.offset().top;

			$this.css({
				"margin-top": mainOffY - thisOffY,
				visibility: "visible"
			});
		});
	}

	window.setTimeout(repositionServiceCarousel, 500);

	$(".service-carousel").each(function() {
		if ($(this).children(".slide").length > 1) {
			$(this).owlCarousel({
				navigation: false, // Hide next and prev buttons
				autoPlay: 7000,
				pagination: false,
				autoHeight: false,
				singleItem: true,
				mouseDrag: false,
				touchDrag: false,
				transitionStyle: "fade"
			});
		}
	});

	$(window).on("resize.service-carousel", function(e) {
		window.clearTimeout(serviceTimer);

		serviceTimer = window.setTimeout(repositionServiceCarousel, 100);
	});

	// Services navigation
	$(".services-navigation a").on("click", function(e) {
		e.preventDefault();

		$("html, body").animate(
			{ scrollTop: $($(this).attr("href")).offset().top - 130 },
			600
		);

		return false;
	});

	let $outsourcingTabs = $(".outsourcing-tabs");

	const handleFloatNav = $nav => {
		let padding = 10;
		let $container = $(".container").first();

		$nav
			.children()
			.first()
			.outerWidth() +
			padding <
		($(document).width() - $container.outerWidth()) / 2
			? $nav.css({
					opacity: 1,
					left: ($container.offset().left - $nav.outerWidth()) / 2.5
			  })
			: $nav.css("opacity", 0);
	};

	$outsourcingTabs
		.sticky({
			topSpacing: 120,
			wrapperClassName: "outsourcing-tabs-sticky-wrapper",
			responsiveWidth: true
		})
		.on("sticky-start", () => {
			handleFloatNav($outsourcingTabs);
			$(window).on(
				"resize",
				_throttle(() => handleFloatNav($outsourcingTabs), 500)
			);
		});

	const customWrapper = {
		radio: ($wrapper, $input, $label) => {
			$wrapper.addClass("custom-control custom-radio");
			$input.addClass("custom-control-input");
			$label.addClass("custom-control-label");
		},
		checkbox: ($wrapper, $input, $label) => {
			$wrapper.addClass("custom-control custom-checkbox");
			$input.addClass("custom-control-input");
			$label.addClass("custom-control-label");
		},
		file: ($wrapper, $input, $label) => {
			$wrapper.addClass("custom-file");
			$input.addClass("custom-file-input");
			$label
				.addClass("custom-file-label")
				.text($label.text().replace(":", ""));
		}
	};

	const wrapCustomElements = ($input, $label, type) => {
		const $wrapper = $("<div>");
		customWrapper[type]($wrapper, $input, $label);

		$input.add($label).wrapAll($wrapper);
	};

	$(
		".radio-list-vertical input[type=radio], .radio-list-horizontal input[type=radio], .checkbox-list-vertical input[type=checkbox]"
	).each(function() {
		wrapCustomElements(
			$(this),
			$(this).next("label"),
			$(this).attr("type")
		);
	});

	$(".uploader-input-file").each(function() {
		wrapCustomElements(
			$(this),
			$(this)
				.closest("#Files")
				.find(".FieldLabel label"),
			$(this).attr("type")
		);
	});

	if ($outsourcingTabs.length > 0) {
		if (window.location.hash !== "") {
			let $tab = $(`[data-target="${window.location.hash}"]`);

			$tab.tab("show");
		} else {
			$outsourcingTabs
				.find("[data-target]")
				.first()
				.tab("show");
		}

		window.scrollTo(0, 0);
	}

	const activateOutsourcingTab = e => {
		e.preventDefault();

		window.location.hash = $(e.currentTarget).data("target");
		setTimeout(() => {
			$("html, body").animate(
				{
					scrollTop: 0
				},
				250,
				"linear"
			);
		}, 100);
	};

	$outsourcingTabs.on("click", "[data-target]", e =>
		activateOutsourcingTab(e)
	);
	$("[data-ext-target]").on("click", e => {
		e.preventDefault();
		let target = $(e.target).data("ext-target");
		$outsourcingTabs.find(`[data-target="${target}"]`).trigger("click");
	});
});
