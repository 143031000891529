// require('vendor');

$(function(){
	if($('.scroll-to-top').length === 1) return false;

	var $scrollBtn = $('<button class="scroll-to-top"><span class="fa fa-angle-up"></span></button>'),
		$footer = $('.page-footer'),
		scrollDuration = 400,
		fadeDuration = 250,
		topOffset = 220;

	$(window).on('scroll.scrollToTop', function(e){
		createScrollBtn();
	})

	$scrollBtn.on('click', function(e) {
		e.preventDefault();

		$('html, body').animate({scrollTop: 0}, scrollDuration);
		
		return false;
	});

	function createScrollBtn(){
		// if($(window).scrollTop() + $(window).height() >= $footer.offset().top - 50 && $(window).scrollTop() > topOffset) {
		if ($(window).scrollTop() > topOffset) {
			$scrollBtn
				.appendTo('.page-wrapper')
				.css({
					position: 'absolute',
					bottom: $footer.outerHeight()
				})
				.fadeIn(fadeDuration);

			initScrollBtn();
			
			$(window).off('scroll.scrollToTop');
		}
	}

	function initScrollBtn(){

		$(window).on('scroll', function() {
			if ($(this).scrollTop() > topOffset) {
				$scrollBtn.fadeIn(fadeDuration);
			} 
			else {
				$scrollBtn.fadeOut(fadeDuration);
			}

			if ($(window).scrollTop() + $(window).height() > $footer.offset().top) {
				$scrollBtn.css({
					position: 'absolute',
					bottom: $footer.outerHeight()
				})
			}
			else {
				$scrollBtn.css({
					position: 'fixed',
					bottom: '0'
				})
			}
		});
	}
})
